.sliderContainer {
  width: 100%;
  display: grid;
}
.slideItemContainer {
  width: 100%;
  height: 80vh;
  position: relative;
}
.navigationWrapper {
  margin: 50px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  column-gap: 5px;
}