@import url('https://fonts.googleapis.com/css2?family==Numans&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Numans', sans-serif;
  color: black;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.appContainer {
  margin-top: 60px;
  padding: 0 50px;
}
