.slideImage {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.8s ease;
  background-size: cover;
  //animation:  3s linear 0s slidein;

  &.inactiveLeft {
    transform: translateX(calc(-100% - 1px));
  }

  &.inactiveRight {
    transform: translateX(calc(100% + 1px));
  }

  &.currentActive {
    transform: translateX(0);
  }
}

@keyframes slidein {
  0% {
    object-position: 150% 50%;
  }

  100% {
    object-position: 100% 50%;
  }
}