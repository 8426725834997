.slideItemContainer {
  position: absolute;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.activeSlide {
    z-index: 1;
  }
}

.slideTitle {
  z-index: 1;
  color: #eee;
  transform: scale(0.8);
  opacity: 0;
  font-size: 50px;
  text-align: center;
  transition: transform 0.5s 0.4s ease, opacity 0.5s 0.5s ease;;
  pointer-events: none;
  user-select: none;
}

.slideButton {
  z-index: 1;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 15px 30px;
  text-transform: uppercase;
  border: 1px solid #eee;
  max-width: 150px;
  letter-spacing: 4px;
  font-size: 0.8rem;
  transition: background-color 0.5s ease;
  background-color: rgba(255,255,255,0);

  &:hover {
    background-color: rgba(255,255,255,0.6);
    color: #000;
  }
}

.slideListParent,
.slideListParent li {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.slideListParent {
  list-style: none;
  position: absolute;
  grid-area: center;
  display: grid;
  grid-auto-flow: column;
  height: 80vh;
}

.slideItemList {
  list-style: none;
  overflow: hidden;
  width: 100%;
  position: relative;
  transition: transform 1s ease;
}

.activeSlide {
  .slideTitle {
    transform: scale(1);
    opacity: 1;
    pointer-events: auto;
    user-select: auto;
  }
}